import Home from './pages/Registration';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Welcome from './pages/Welcome';
import ThankyouPage from './pages/ThankyouPage';
import Preview from './pages/Preview';
import { ToastContainer } from 'react-toastify';
import { GuestCampClient } from '@snayvik-tech-labs/guestcamp-api';


GuestCampClient.init({
  base_url: process.env.REACT_APP_BASE_URL,
  api_token: process.env.REACT_APP_PROXIMUS_API_KEY,
  client_name: process.env.REACT_APP_CLIENT_NAME,
  event: process.env.REACT_APP_EVENT_NAME,
  cloudnary: {
    base_url: process.env.REACT_APP_CLOUDINARY_BASE_URL,
    cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
    cloud_preset: process.env.REACT_APP_CLOUDINARY_CLOUD_PRESET,
  }
})
function App() {
  return (
    <div className="App main-container">

      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/registration" element={<Home />} />
          <Route path="/preview" element={<Preview />} />
          <Route path="/thankyou" element={<ThankyouPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
