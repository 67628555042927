import React from 'react'
import { Link } from 'react-router-dom'

function Logo() {
  return (
    <div className="logo-container">
      {/* <h2 className="logo-txt"><Link to="/"><img src="/assests/images/oneplus_sign.png" alt="oneplus" className="logo" />ONEPLUS </Link></h2> */}
      {/* <h2 className="logo-txt"> */}
      <Link to="/"><img src="/assests/images/logo.png" alt="oneplus" className="img-fluid logo" /></Link>
      {/* </h2> */}
    </div>
  )
}

export default Logo