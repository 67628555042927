import React from 'react';
import Logo from '../components/logo';
import { useState, useEffect } from 'react';
// import { useState } from "react";
// import './App.css';

function App() {
    const [selectedImage, setSelectedImage] = useState(0);

    //   const handleImageChange = (event) => {
    //     const file = event.target.files[0];
    //     const reader = new FileReader();

    //     reader.onloadend = () => {
    //       setSelectedImage(reader.result);
    //     };
    //     reader.readAsDataURL(file);
    //   };

    //   const handleConfirm = () => {
    //     // Handle image confirmation
    //     console.log('Confirmed image:', selectedImage);
    //   };

    // useEffect(() => {
    //     console.info({ selectedImage })
    // fetch('https://jsonplaceholder.typicode.com/posts')
    // .then(res => res.json())
    // .then(console.info);
    // }, [selectedImage])


    return (


        <>
            <div className="container">
                <Logo />
                {/* <button onClick={(e) => {
                e.preventDefault();
                setSelectedImage((pre) => {
                    return pre + 1
                })
            }} >press me</button> */}
                <div className="transbox">
                    <div className="row">
                        <div className="col-12 col-lg-6 column-class">
                            <h5>Please select your picture</h5>

                        </div>
                        <div className="col-12 col-lg-6 column-class">
                            <div class="custom-file-input">
                                <input type="file" id="img" name="img" accept="image" />
                                <label for="img">
                                    <button type="button" ><span>Change selection</span></button>
                                    <i class="fas fa-camera"></i>
                                </label>
                            </div>
                            <button type="submit">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;