import React, { useEffect } from 'react';
import Logo from '../components/logo';
import { useNavigate } from 'react-router-dom';
// import { useHistory } from 'eact-router-dom';

function ThankyouPage() {

    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            navigate('/');
        }, 10000);
        return () => clearInterval(interval);
    });

    return (

        <div className="">
            <Logo />


            <div className="thankyou-container d-flex justify-content-center align-items-center">
                <div className="thankyou-text-container text-center mx-md-auto">
                    <h1 className="thank-txt text-color">THANK YOU</h1>
                    <h2 className="thnx-content text-color">Please check your mailbox* for your picture</h2>
                    <p className="text-content">*Don't forget to check your spam folder if you can't find our mail.</p>
                </div>
            </div>

        </div>
    );
}

export default ThankyouPage;