import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import Logo from '../components/logo';

function Welcome() {

    return (
        <Link to="/registration">
            <div>
                <Logo />

                <div className="welcome-container d-flex justify-content-center align-items-center">
                    <div className="text-container text-center mx-md-auto">
                        <h1 className='txt-wlc text-color'>WELCOME</h1>
                        <p className='txt-wlc-content text-color'>Tap anywhere to continue...</p>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default Welcome;
