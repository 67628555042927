import axios from "axios";
import React, { useEffect, useState } from "react";
import { Utils } from "../helper/Utils";
import { useNavigate } from 'react-router-dom';
import Logo from "../components/logo";
import { PreviewSection } from "../components/PreviewSection";

import { GuestCampClient } from '@snayvik-tech-labs/guestcamp-api';
import Loder from "../components/Loder";
import useScreenOrientation from "../hooks/getOrientation";



function Home() {
    const PROXIMUS_BASE_URL = process.env.REACT_APP_PROXIMUS_BASE_URL;
    const [fields, setFields] = useState([]);
    const [fieldLoading, setFieldLoading] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [data, setData] = useState({});
    const [mailRecipient, setMailRecipient] = useState(); //mail recpient id 5399
    const orientation = useScreenOrientation();
    console.info({ orientation })
    const navigate = useNavigate();

    useEffect(() => {
        setFieldLoading(true)
        GuestCampClient.GetFields().then(fiels => {
            setFields(fiels)
            setFieldLoading(false)
        })
    }, [])


    const handleChange = (e) => {
        const { name, value, type } = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "checkbox" ? e.target.checked : value,
        }));
    };


    const handleSubmit = (e) => {
        e.preventDefault();


        setUploading(true);

        const fieldData = { ...data };
        const payload = {
            fields: fieldData,
            locale: "en",
            email: fieldData.email,
        };

        axios
            .post(`${PROXIMUS_BASE_URL}/mail-recipient`, payload, {
                headers: {
                    'api-token': process.env.REACT_APP_PROXIMUS_API_KEY,
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                console.log("Form submitted successfully:", response.data);
                const mailRecipientId = response.data.id; // Extract mail-recipient ID from response
                setMailRecipient(mailRecipientId);
            })
            .catch((error) => {
                console.error("Error submitting form:", error);
                alert("Failed to submit form. Please try again later.");
                setUploading(false); // Reset uploading state in case of form submission failure
            });
    };

    const isLandscape = orientation === 'landscape-primary';

    return (
        <div className="reg-container">
            <Logo />
            <div className={`reg-inner-sec ${isLandscape ? "here" : ""}`}>
                <div className="container">
                    {!mailRecipient ? <div className="registration-container">
                        {/* <RegistrationUI locale={"fr"}
                        onFieldsLoading={() => console.info("onFieldsLoading")}
                        onFieldRequestFail={(error) => console.info("onFieldRequestFail " + error)}
                        onFieldRequestSuccess={() => console.info("onFieldRequestSuccess")}
                        onFieldsLoaded={() => console.info("onFieldsLoaded")}
                        onRegistrationFail={(error) => console.info("onRegistrationFail " + error)}
                        onRegistrationRequestStart={() => console.info("onRegistrationRequestStart")}
                        onRegistrationSuccess={(data) => console.info("onRegistrationSuccess " + data)}
                    /> */}
                        <form className="form-tag form-section" onSubmit={handleSubmit} autoComplete="off" encType="multipart/form-data">
                            <div className="head-div">
                                <h5>Please fill out this form to receive your photo</h5>
                            </div>
                            {!fieldLoading ? fields.map((field) => (
                                <div className="form-main-div" key={`field_${field.id}`}>
                                    {["text", "email", "number"].includes(field.field_type) && (
                                        <div className="mb-4 row align-items-center">
                                            <div className="col-lg-9 col-md-12 col-sm-12">
                                                <input
                                                    placeholder={Utils.getFieldName(field.name, "en")}
                                                    type={field.field_type}
                                                    name={field.slug}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )) : (<Loder />)

                            }
                            {
                                !fieldLoading && <div className="form-control-div text-end">
                                    <button className="submit-btn position-relative">
                                        {uploading ? <Loder /> : "Confirm"}
                                    </button>
                                    <span className="confirm-arrow"><svg viewBox="0 0 32 32"
                                        xmlns="http://www.w3.org/2000/svg"><path
                                            d="M22 9a1 1 0 0 0 0 1.42l4.6 4.6H3.06a1 1 0 1 0 0 2h23.52L22 21.59A1 1 0 0 0 22 23a1 1 0 0 0 1.41 0l6.36-6.36a.88.88 0 0 0 0-1.27L23.42 9A1 1 0 0 0 22 9Z"
                                            data-name="Layer 2" fill="#ffffff" className="fill-000000"></path></svg></span>
                                </div>
                            }
                        </form>
                    </div>
                        :
                        <PreviewSection mailRecipient={mailRecipient} />
                    }
                </div>
            </div>
        </div>
    );
}

export default Home;
