import axios from 'axios';
import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Loder from './Loder';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GuestCampClient } from '@snayvik-tech-labs/guestcamp-api';

export const PreviewSection = ({ mailRecipient }) => {
    const PROXIMUS_BASE_URL = process.env.REACT_APP_PROXIMUS_BASE_URL;
    const [file, setFile] = useState();
    const [imageSrc, setImageSrc] = useState();
    const [responseModal, setResponseModal] = useState(false);
    const [error, setError] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [quit, setQuit] = useState(false);

    const navigate = useNavigate();

    const filehandleChange = (event) => {
        setFile(event.target.files[0]);
        setImageSrc(URL.createObjectURL(event.target.files[0]));
    };

    const handleImageClick = () => {
        document.getElementById('preview_img').click();
    };

    const handleFileSubmit = (evt) => {
        setUploading(true);
        evt.preventDefault();
        // const formData = new FormData();
        // formData.append('file', file); // Append only the file to FormData
        // formData.append('mail_recipient_id', mailRecipient); // Append only the file to FormData

        GuestCampClient.UploadMedia({
            file,
            event_id: 5,
            mail_recipient_id: mailRecipient
        })
            .then((response) => {

                console.info(response);
                evt.target.reset(); // Reset the form after successful submission
                // setResponseModal(true)
                toast.success("File upload successfully.", {
                    className: "foo-bar, toast-color text-color",
                });
                // toast.success('File upload successfully.');
                setError(false)
                setUploading(false);
                navigate('/thankyou')
            })
            .catch((error) => {
                // console.error("Error submitting media-object:", error);
                // setResponseModal(true)
                toast.error("Oops Something went wrong...", {
                    className: "foo-bar toast-color text-color",
                });
                setError(true);
                setUploading(false);
            })

    };


    return (
        <div className="transbox">
            <form className="row form-sec" onSubmit={handleFileSubmit} encType="multipart/form-data">
                <div className='col-md-12'>
                    <div className='inner-sec'>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-12 col-lg-6 col-sm-6 column-class'>
                                    <h5 className='upload-head'>Please select your picture</h5>
                                    <div className="custom-file-input" onClick={handleImageClick}>
                                        <img src={!imageSrc ? "/assests/images/tap_upload.png" : imageSrc} />
                                    </div>
                                </div>

                                <div className='col-12 col-lg-6 col-sm-6  sub-section column-class'>
                                    <label className='inner-sub text-white' forhtml="preview_img">
                                        <input type="file" id='preview_img' accept="image/*" required name="file" className="form-control d-none" onChange={filehandleChange} />
                                        <div className='choose-btn' >Change selection</div>
                                    </label>
                                    <div>
                                        <button className='sbutton' type="submit">
                                            {uploading ? <Loder /> : "Confirm"}
                                        </button>
                                        <span className="confirm-arrow"><svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M22 9a1 1 0 0 0 0 1.42l4.6 4.6H3.06a1 1 0 1 0 0 2h23.52L22 21.59A1 1 0 0 0 22 23a1 1 0 0 0 1.41 0l6.36-6.36a.88.88 0 0 0 0-1.27L23.42 9A1 1 0 0 0 22 9Z" data-name="Layer 2" fill="#ffffff" class="fill-000000"></path></svg></span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

